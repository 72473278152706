import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Validating Demand For Product Ideas`}</h1>
    <p><strong parentName="p">{`Day 11 - Today`}</strong></p>
    <p>{`Yesterday was all about phoning some friends and getting feedback & it ended with a few ideas that looked good on paper`}</p>
    <p>{`Blushie,`}</p>
    <p>{`Remember that every day we need to set our OBT….`}</p>
    <p><strong parentName="p">{`Today’s OBT:`}</strong><br parentName="p"></br>{`
`}{`I just did a call with Ben and decided that today’s OBT (`}<a parentName="p" {...{
        "href": "https://soundcloud.com/shaan-puri-429311607/916-daily-planning/s-2BeML48ysEQ"
      }}>{`you can listen in here`}</a>{`) is getting our product list narrowed down by looking at demand`}</p>
    <p>{`So today is all about going through a methodical process into seeing what demand exists and if it’s clearly strong enough for us to go after.`}</p>
    <p>{`When it comes to testing this - a lot of people jump to creating facebook ads and seeing if people buy. That’s a mistake imho, i’d rather figure out what’s already happening out there vs. wasting time making a landing page and running ads`}</p>
    <p>{`I made a Loom (`}<strong parentName="p">{`yeah, multimedia again!`}</strong>{`) to show you the step-by-step process that I'm using to do this (`}<strong parentName="p">{`it’s ~15 minutes start to finish`}</strong>{`):`}</p>
    <div style={{
      "position": "relative",
      "paddingBottom": "78.34602829162132%",
      "height": "0"
    }}><iframe src="https://www.loom.com/embed/9821fc28441c4d0ea19ef6c2b1111dc7" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></div>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.loom.com/share/9821fc28441c4d0ea19ef6c2b1111dc7"
        }}>{`WATCH THE LOOM HERE`}</a></strong></p>
    <p><strong parentName="p">{`Here are the links to the tools i talk about in the video:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.junglescout.com/"
        }}>{`Junglescout`}</a>{` - look at sales volume for any search on Amazon`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.importgenius.com/"
        }}>{`Import Genius`}</a>{` - look at import records for everything coming into the country; shipping big weight = selling big volumes`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/ads/library/?active_status=all&ad_type=political_and_issue_ads&country=US"
        }}>{`Facebook Ad Library`}</a>{` - look up ads that are running on facebook or ig for any brand`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kwfinder.com/"
        }}>{`KWFinder`}</a>{` - look up how much search volume is happening`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://trends.google.com/trends/?geo=US"
        }}>{`Google Trends`}</a>{` - look up macro trends around a specific topic`}</li>
      <li parentName="ul">{`Shopify Receipts - buy a product and look at the # on the receipt...all shopify stores start at 1,000, so you can see how many orders have been placed`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://myip.ms/browse/sites/1/ipID/23.227.38.0/ipIDii/23.227.38.255/sort/2/asc/1/"
        }}>{`Shopify Traffic`}</a>{` - look up how much daily traffic a specific shopify store has`}</li>
    </ul>
    <p>{`Now you have my playbook to looking at ecom product demand - use it next time you're cooking something up`}</p>
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <p>{`P.S. my friend `}<a parentName="p" {...{
        "href": "https://superorganizers.substack.com/p/the-four-kinds-of-side-hustles"
      }}>{`Justin wrote this great post today on Substack`}</a>{` about side hustles and how he thinks about em. Highly recommend`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://www.junglescout.com/',
        label: 'Junglescout'
      }, {
        url: 'https://www.importgenius.com/',
        label: 'Import Genius'
      }, {
        url: 'https://www.facebook.com/ads/library/?active_status=all&ad_type=political_and_issue_ads&country=US',
        label: 'Facebook Ad Library'
      }, {
        url: 'https://kwfinder.com/',
        label: 'KWFinder'
      }, {
        url: 'https://trends.google.com/trends/?geo=US',
        label: 'Google Trends'
      }, {
        url: 'https://myip.ms/browse/sites/1/ipID/23.227.38.0/ipIDii/23.227.38.255/sort/2/asc/1/',
        label: 'Shopify Traffic'
      }]
    }, {
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://www.loom.com/share/9821fc28441c4d0ea19ef6c2b1111dc7',
        label: 'Ecommerce - Validating Demand Loom'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://superorganizers.substack.com/p/the-four-kinds-of-side-hustles',
        label: 'Justin Mares - The Four Kinds of Side Hustles'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.the_power_box.label,
      slug: slugify(postMap.the_power_box.slug)
    }} nextButton={{
      text: postMap.tools_tips_tricks_to_finding_a_manufacturer.label,
      slug: slugify(postMap.tools_tips_tricks_to_finding_a_manufacturer.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      